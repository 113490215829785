export const seo = {
  url: 'paperliner',
  title: {
    en: 'Paperliner | Paper transport with Joloda trailers',
    es: 'Paperliner | Transporte de papel con remolques Joloda',
    ro: 'Paperliner | Transport hartie cu remorci Joloda',
  },
  desc: {
    en: 'The Joloda system means safety, space optimization and easy loading. Omida Transport Solution is an innovation in transport for printing houses and paper producers.',
    es: 'El sistema Joloda significa seguridad, optimización del espacio y facilidad de carga. Omida Transport Solution es una innovación en el transporte para imprentas y productores de papel',
    ro: 'Sistemul Joloda înseamnă siguranță, optimizare a spațiului și încărcare ușoară. Omida Transport Solution este o inovație în transport pentru tipografii și producătorii de hârtie.',
  },
  keywords: [
    'paperliner',
    'joloda',
    'papier',
    'transport',
    'system',
    'logistics',
  ],
}

export const intro = {
  title: {
    en: 'Paperliner',
    es: 'Paperliner',
    ro: 'Paperliner',
  },
  desc: {
    en: 'The Joloda system means safety, space optimization and easy loading. Omida Transport Solution is an innovation in transport for printing houses and paper producers.',
    es: 'El sistema Joloda significa seguridad, optimización del espacio y facilidad de carga. Omida Transport Solution es una innovación en el transporte para imprentas y productores de papel',
    ro: 'Sistemul Joloda înseamnă siguranță, optimizare a spațiului și încărcare ușoară. Omida Transport Solution este o inovație în transport pentru tipografii și producătorii de hârtie.',
  },
  // button: {
  //   text: {
  //     en: 'Download the catalog',
  //     es: 'Descarga el catálogo',
  //     ro: 'Descărcați catalogul',
  //   },
  //   link: {
  //     en: '/pdf/paperliner_en.pdf',
  //     es: '/pdf/paperliner_en.pdf',
  //     ro: '/pdf/paperliner_en.pdf',
  //   },
  // },
  button: {
    text: {
      en: 'Read more',
      es: 'Leer más',
      ro: 'Citește mai mult',
    },
    action: 'SCROLL',
  },
}

export const main = {
  body: [
    {
      headline: {
        en: 'A Safe Way To Transport Paper',
        es: 'Manera segura de transportar papel',
        ro: 'O modalitate sigură de a transporta hârtie',
      },

      texts: [
        {
          en: 'Roll paper is a heavy and thick very delicate load. That is why our company, facing the challenge, prepared specially dedicated products for producers to accelerate the process of loading, unloading and transporting paper.',
          es: 'El rollo de papel es una carga pesada y, al mismo tiempo, muy delicada. Es por eso que nuestra empresa, ante el desafío, ha preparado soluciones especiales dedicadas a los productores de papel destinadas a acelerar el proceso de carga, descarga y transporte de papel.',
          ro: 'Rola de hârtie este o încărcătură grea și groasă, foarte delicată. Tocmai de aceea, compania noastră, in fata provocării, a pregătit produse special dedicate producătorilor pentru a accelera procesul de încărcare, descărcare și transport hârtie.',
          image: 'paperliner1',
        },
        {
          en: 'The solutions used in paper liner trailers are not limited only to the transport of paper in rolls, they are an ideal means of transporting products such as packaging, press and books, where the priority is the speed of loading and unloading, as well as ensuring the absence of damage and maximizing the use of cargo space.',
          es: 'Las soluciones utilizadas en los remolques paper liner no se limitan únicamente al transporte de papel en rollos, son un medio ideal para el transporte de productos como embalajes, prensa y libros, donde prima la rapidez de carga y descarga, además de garantizar la ausencia de daños y maximizar el uso del espacio de carga.',
          ro: 'Soluțiile utilizate în remorcile pentru căptușeli de hârtie nu se limitează la transportul hârtiei în rulouri, ci reprezintă un mijloc ideal pentru transportul unor produse cum ar fi ambalajele, ziarele și cărțile, în cazul în care viteza de încărcare și descărcare este primordială, precum și pentru a garanta absența deteriorării și utilizarea maximă a spațiului de încărcare.',
          image: 'paperliner2',
        },
        {
          en: 'Our company, thanks to the care of a forwarder 24/7, 365 days a year, is able to provide a professional service related to the distribution of the press, which is provided with exceptional punctuality.',
          es: 'Gracias a la atención de un transitario 24/7, los 365 días del año, nuestra empresa es capaz de brindar un servicio profesional relacionado con la distribución de prensa, el cual se brinda con una puntualidad excepcional.',
          ro: 'Firma noastră, datorita grijii unui expeditor 24/7, 365 zile pe an, este capabilă să ofere un serviciu profesional legat de distribuția presei, care este asigurat cu o punctualitate excepțională.',
          image: 'paperliner3',
        },
      ],
    },
    {
      headline: {
        en: 'Paperliner',
        es: 'Paperliner',
        ro: 'Paperliner',
      },
      texts: [
        {
          en: 'Following the principle of innovation in action, we present paper liner semi-trailers. Specialized longitudinal rails and a system of eyelets enabling the adaptation of securing wedges allow for quick horizontal loading of paper in rolls and horizontal location of the rolls at any point on the trailer. This innovative solution is not possible with standard trailers used by competitors. In addition, our semi-trailers are equipped with Joloda self-propelled trolleys, thanks to which the driver can load or unload goods without the need to use a trolley or a ramp, which significantly reduces the time of loading or unloading.',
          es: 'Siguiendo el principio de innovación en acción, presentamos los semirremolques de revestimiento de papel. Rieles longitudinales especializados y un sistema de ojales que permiten la adaptación de cuñas de sujeción permiten una carga horizontal rápida de papel en rollos y la ubicación horizontal de los rollos en cualquier punto del remolque. Esta innovadora solución no es posible con los remolques estándar utilizados por la competencia. Además, nuestros semirremolques están equipados con carros autopropulsados Joloda, gracias a los cuales el conductor puede cargar o descargar mercancías sin necesidad de utilizar carro o rampa, lo que reduce significativamente el tiempo de carga o descarga.',
          ro: 'Urmând principiul inovației în acțiune, vă prezentăm semiremorci cu garnitură de hârtie. Șinele longitudinale specializate și un sistem de ochiuri care permit adaptarea penelor de fixare permit încărcarea rapidă orizontală a hârtiei în role și amplasarea orizontală a rolelor în orice punct al remorcii. Această soluție inovatoare nu este posibilă cu remorcile standard utilizate de concurenți. În plus, semiremorcile noastre sunt echipate cu cărucioare autopropulsate Joloda, datorită cărora șoferul poate încărca sau descărca mărfuri fără a fi nevoie să folosească un cărucior sau o rampă, ceea ce reduce semnificativ timpul de încărcare sau descărcare.',
          image: 'paperliner4',
        },
      ],
    },
    {
      headline: {
        en: 'Joloda System',
        es: 'Joloda System',
      },
      texts: [
        {
          en: 'Joloda is a system for self-loading / unloading of long and heavy paper pallets in rolls and atypical machines. The system consists of four special rails (guides) mounted in the floor of the trailer, on which the slats equipped with rollers (Joloda) move. With the help of the Joloda system, the driver lifts the goods above the surface of the trailer and moves it forwards or backwards.',
          es: 'Joloda es un sistema de autocarga/descarga de palets de papel largos y pesados ​​en rollos y máquinas atípicas. El sistema consta de cuatro rieles especiales (guías) montados en el piso del remolque, sobre los cuales se mueven las lamas provistas de rodillos (Joloda). Con la ayuda del sistema Joloda, el conductor eleva la mercancía por encima de la superficie del remolque y la mueve hacia delante o hacia atrás.',
          ro: 'Joloda este un sistem de autoîncărcare/descărcare a paleților de hârtie lungi și grei in role si mașini atipice. Sistemul este format din patru șine (ghidaj) speciale montate în podeaua remorcii, pe care se deplasează șipcile echipate cu role (Joloda). Cu ajutorul sistemului Joloda, șoferul ridică marfa deasupra suprafeței remorcii și o deplasează înainte sau înapoi.',
          image: 'paperliner5',
        },
      ],
    },
  ],
}

export const form = {
  id: 'form',
  title: {
    en: 'Quick Message Paperliner',
    es: 'Consulta rápida Paperliner',
    ro: 'Paperliner pentru mesaje rapide',
  },
  desc: {
    en: 'Send a request - we will contact you ASAP',
    es: 'Envíe una solicitud - nos pondremos en contacto con usted lo antes posible',
    ro: 'Trimiteți o cerere - vă vom contacta în cel mai scurt timp',
  },
  fields: {
    email: {
      label: { en: 'Email', es: 'Email', ro: 'Email' },
      placeholder: { en: '', es: '', ro: '' },
      type: 'text',
      isRequired: true,
    },
    name: {
      label: { en: 'Contact person', es: 'Persona de contacto', ro: 'Persoana de contact' },
      placeholder: { en: '', es: '', ro: '' },
      type: 'text',
      isRequired: true,
    },
    message: {
      label: { en: 'Message', es: 'Mensaje', ro: 'Mesaj' },
      placeholder: { en: '', es: '', ro: '' },
      type: 'message',
      isRequired: true,
    },
  },
  agreements: [
    {
      en: 'I agree to receive by e-mail commercial information coming from Omida VLS Sp. z o.o. within the meaning of the Act of 18 July 2002 on electronic services',
      es: 'Acepto recibir información comercial por medios electrónicos en el sentido de la Ley de 18 de julio de 2002 sobre prestación de servicios electrónicos con contenidos de Omida VLS Sp. z o.o.',
      ro: 'Sunt de acord să primesc prin e-mail informații comerciale provenite de la Omida VLS Sp. z o.o. în sensul Legii din 18 iulie 2002 privind serviciile electronice',
    },
    {
      en: 'I hereby consent to the use by Omida VLS Sp. z o.o. of telecommunications terminal equipment and automated calling systems to present commercial information within the meaning of the Act of 16 July 2014. -Telecommunications Law',
      es: 'Consiento el uso por parte de Omida VLS Sp. z o.o. equipos terminales de telecomunicaciones y sistemas automáticos de llamada para presentar información comercial en el sentido de lo dispuesto en la Ley de 16 de julio de 2014 - Ley de Telecomunicaciones',
      ro: 'Sunt de acord cu utilizarea de către Omida VLS Sp. z o.o. a echipamentelor terminale de telecomunicații și a sistemelor automate de apelare pentru a prezenta informații comerciale în sensul Legii din 16 iulie 2014. -Legea telecomunicațiilor',
    },
  ],
  administrating: {
    en: 'The administrator of your personal data will be Omida VLS Sp. z o.o.(hereinafter: Omida). You can contact us as follows: by letter to: Aleja Grunwaldzka 472C, 80-309 Gdańsk, by e-mail:',
    es: 'El administrador de sus datos personales será Omida VLS Sp. z o.o. (en adelante: Omida). Puede ponerse en contacto con nosotros de la siguiente manera: por carta a la siguiente dirección: Aleja Grunwaldzka 472C, 80-309 Gdańsk, por correo electrónico:',
    ro: 'Administratorul datelor dumneavoastră personale va fi Omida VLS Sp. z o.o. (în continuare: Omida). Ne puteți contacta după cum urmează: prin scrisoare către: Aleja Grunwaldzka 472C, 80-309 Gdańsk, prin e-mail:',
  },
  administratingSecond: {
    en: ' or by phone: +48 58 741 88 14. Personal data will be processed in order to prepare offers on Omida products and services. Details about the processing of your personal data are available',
    es: ' o por teléfono: +48 58 741 88 14. Trataremos los datos personales para preparar ofertas de productos y servicios de Omida. Puede encontrar información detallada sobre el procesamiento de datos personales',
    ro: ' sau la telefon: +48 58 741 88 14. Datele personale vor fi prelucrate în vederea pregătirii ofertelor pentru produsele și serviciile Omida. Detalii despre prelucrarea datelor dumneavoastră cu caracter personal sunt disponibile',
  },
  administratingLinkText: {
    en: 'here',
    es: 'aquí',
    ro: 'aici',
  },
  dictonary: {
    success: {
      title: { en: 'Thank you!', es: '¡Gracias!', ro: '¡Gracias!' },
      text: {
        en: 'Your message has been sent. We will reply as soon as possible. We also sent you the confirmation to provided email address',
        es: 'Tu mensaje ha sido enviado. Le contestaremos lo antes posible. También enviamos una confirmación del mensaje a la dirección proporcionada',
        ro: 'Mesajul tau a fost trimis. Vă vom răspunde cât mai curând posibil. De asemenea, v-am trimis confirmarea la adresa de e-mail furnizată',
      },
    },
    failure: {
      title: { en: 'Failure!', es: '¡Falla!', ro: 'Eșec!' },
      text: {
        en: 'It looks like your message has not been sent. Contact us by e - mail or by phone',
        es: 'Parece que su mensaje no ha sido enviado. Contáctenos por correo electrónico o por teléfono',
        ro: 'Se pare că mesajul tău nu a fost trimis. Contactați-ne prin e-mail sau telefonic',
      },
    },
    send: {
      en: 'Send',
      es: 'Enviar',
      ro: 'Trimiteți',
    },
    fill: {
      en: 'Fill up the form',
      es: 'Rellene el formulario',
      ro: 'Completați formularul',
    },
    fillagreements: {
      en: 'Agree to the terms',
      es: 'Seleccione el consentimiento',
      ro: 'De acord cu termenii',
    },
  },
}
export const buttonBack = {
  text: {
    en: 'Ask for an offer',
    es: 'Pide una oferta',
    ro: 'Cereți o ofertă',
  },
  link: '/paperliner/#form',
}
